<template>
	<div id="messageList" class="el-content">
		<a-button type="primary" @click="pickAllRead">全部已读</a-button>
		<a-table row-key="message_id" :pagination="false" :data-source="info.list" :columns="[
			{title:'ID',dataIndex:'message_id'},
			{title:'标题',dataIndex:'title'},
			{title:'内容',dataIndex:'content',slots:{customRender:'content'}},
			{title:'是否已读',dataIndex:'is_read',slots:{customRender:'is_read'}},
			{title:'时间',dataIndex:'send_time'}
		]" >
			<template #content="{record}">
				<span style="cursor: pointer;"  @click="lookDetail(record)">{{record.content}}</span>
			</template>
			<template #is_read="{record}">
				<a-tag :color="record.is_read ==1 ?'#00CC66':'#FF3366'">{{ record.is_read == 1 ?'已读':'未读'}}</a-tag>
			</template>
		</a-table>
		<div class="pager">
			<a-pagination 
				show-size-changer 
				v-model:current="info.page" 
				v-model:pageSize="info.limit" 
				:total="info.count" 
				@showSizeChange="(p,e)=>{getMessageList(info.page,e)}"
				@change="(e)=>getMessageList(e,info.limit)"
			/>
		</div>
		
		<a-modal v-model:visible="detailDialog" title="消息详情" :footer="null" @cancel="detailDialog = false" width="600px">
			<div class="kd-message-detail">
				<div class="kd-message-detail-title">{{detail.title}}</div>
				<div class="kd-message-detail-time">{{detail.send_time}}</div>
				<div class="kd-message-detail-content">{{detail.content}}</div>
			</div>
		</a-modal>
	</div>
</template>

<script>
import { reactive, toRefs } from 'vue'
import commonModel from '@/api/common'
export default{
	setup(){
		const state = reactive({
			info:{
				list:[],
				page:1,
				limit:10,
				count:0,
			},
			detailDialog:false,
			detail:[],
		})
		getMessageList(1,state.info.limit)

		function getMessageList(page,limit){
			commonModel.getMessage(page,limit,res=>state.info = {limit,...res})
		}

		const getIsRead = ()=>commonModel.hanldeMessage('read',null,()=>{
			getMessageList(state.info.page,state.info.limit)
		})

		const lookDetail = detail=>commonModel.hanldeMessage('view',{message_id:detail.message_id},res=>{
			state.detail = res
			state.detailDialog = true
			getIsRead()
		})

		const pickAllRead =()=>commonModel.hanldeMessage('readall',null,()=>{
			getMessageList(state.info.page,state.info.limit)
		})

		return{
			...toRefs(state),
			getMessageList,
			getIsRead,
			lookDetail,
			pickAllRead
		}
	}
}
</script>

<style lang="scss">
	.kd-message-detail{
		width: 100%;
		height: auto;
		
		&-title{
			width: 100%;
			height: 20px;
			font-weight: bold;
			font-size: 16px;
			margin-bottom: 10px;
		}
		&-time{
			width: 100%;
			height: 20px;
			color: #868686;
			font-size: 12px;
			margin-bottom: 10px;
		}
		
	}
</style>
